import request from '@/utils/request';

export function QueryAutoCheckList(params) {
  return request({
    url: '/smart_check/data',
    method: 'get',
    params
  });
}

export function ExportAutoCheckList(params) {
  return request({
    url: '/smart_check/export',
    method: 'get',
    responseType: 'blob',
    params
  });
}
